import getConfig from 'next/config';

import { API_PATH } from '@src/config/routes';

import type { ILocation } from '../reducers/locations';
import client from '../utils/client';

export const locationSuggest = async (query: string): Promise<ILocation[]> => {
    const res = await client.get(`${getConfig().publicRuntimeConfig.gatewayUrl}/v1/locations/suggest`, {
        params: {
            q: query,
        },
    });

    return res.data;
};

export const loadMicroCreditsRegions = async (params?: { adsOnly?: boolean; seoActiveCitiesUrl?: string }) => {
    const { data } = await client.get(`${getConfig().publicRuntimeConfig.publicUrl}/v1/microcredit/regions/`, {
        params,
        timeout: 1500,
    });

    return data;
};

export const loadRegions = async (): Promise<ILocation[]> => {
    const { data } = await client.get(`${API_PATH}/location/regions`);

    return data;
};

export const loadRegionalCenters = async (): Promise<ILocation[]> => {
    const { data } = await client.get(`${API_PATH}/location/centers`);

    return data;
};
