interface IRange {
    min: number;
    max: number;
}

export const checkValueInRange = (value: number, { min, max }: IRange) => Math.max(min, Math.min(max, value));

interface IValidateParams {
    max: number;
}

export const validateByParams = (params: IValidateParams) => (value: number | null | undefined, step?: number) => {
    const { max } = params;

    if (value === undefined) return undefined;
    if (value === null || value < 0) return 0;
    if (value > max) return max;

    if (!step) return value;
    return Math.round(value / step) * step;
};
