import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import { loadRegionalCenters, loadRegions } from '@src/services/locations';
import type { ReduxDispatch } from '@src/store/types';
import { storage, StorageKeys } from '@src/utils/storage';

import type { IGlobalState } from '.';

export interface ILocation {
    id: number;
    name: string;
    fullName?: string;
    regionName?: string;
    nameLocative?: string;
    nameGenitive?: string;
    route: string;
    alias: string;
    localityType: string;
    shortLocationType: string;
    latitude: number;
    longitude: number;
}

interface IState {
    regions: ILocation[];
    centers: ILocation[];
    currentLocation: ILocation;
}

const initState: IState = {
    regions: [],
    centers: [],
    currentLocation: {
        id: 83,
        name: 'Москва',
        fullName: 'г Москва',
        nameLocative: 'в Москве',
        nameGenitive: 'Москвы',
        route: '6.83.',
        alias: 'moskva',
        localityType: 'город',
        shortLocationType: 'г.',
        latitude: 55.753676,
        longitude: 37.619899,
    },
};

export const setCurrentLocation = createAction<ILocation>('set current location');
const setLocations = createAction<{ regions: ILocation[]; centers: ILocation[] }>('set locations');

export const locationsReducer = createReducer<IState>({}, initState)
    .on(setCurrentLocation, (state, currentLocation) => ({
        ...state,
        currentLocation,
    }))
    .on(setLocations, (state, { regions, centers }) => ({
        ...state,
        regions,
        centers,
    }));

export const fetchRegions = () => async (dispatch: ReduxDispatch) => {
    let centers: ILocation[] = [];
    let regions: ILocation[] = [];

    const centersData = storage.getItem<ILocation[]>(StorageKeys.CENTER);
    const regionsData = storage.getItem<ILocation[]>(StorageKeys.REGIONS);

    if (!centersData) {
        try {
            centers = await loadRegionalCenters();
            storage.setItem(StorageKeys.CENTER, JSON.stringify(centers));
        } catch (e) {
            console.error(e);
        }
    } else {
        centers = centersData;
    }

    if (!regionsData) {
        try {
            regions = await loadRegions();
            storage.setItem(StorageKeys.REGIONS, JSON.stringify(regions));
        } catch (e) {
            console.error(e);
        }
    } else {
        regions = regionsData;
    }

    dispatch(
        setLocations({
            centers,
            regions,
        }),
    );
};

export const locationsSelector = (state: IGlobalState): IState => state.locations;
export const currentLocationSelector = (state: IGlobalState): IState['currentLocation'] =>
    locationsSelector(state).currentLocation;

export function useCurrentLocation(): IState['currentLocation'] {
    return useSelector<IGlobalState, IState['currentLocation']>(currentLocationSelector);
}
