import getConfig from 'next/config';

import type * as Microcredits from '@src/@types/microcredits';
import type { IPixels } from '@src/utils/promo';
import { updateHasOffersLinks } from '@src/utils/promo';

import client from '../utils/client';

enum GURULEADS_LIST { // продукты выводятся в порядке, указанном тут
    WebZaym = '5e9d4873cc9ae4001b5d7d57',
    PayPs = '5e9c20f69ad83a001b4ae932',
    VivaDengi = '5e9ecd5bd50b49001bf7992e',
    OneClickMoney = '5e981413a66a0f001b32ca05',
    DoZarplaty = '5e95d2be3261b1001b9b5358',
    BystroDengi = '60250eeeb5d14e001f28ba1e',
    MigCredit = '5e625e03dd48ee001b4dcc50',
    MoneyMan = '5e5fc1377a66e4001b2ba980',
    Moneza = '5e95db3f6a6640001b655d46',
    Zaymer = '5e95e240b92fe7001be7598c',
    EKapusta = '5e95c24e15a65c001bab5035',
    Kredito24 = '5e988f68363fc3001b76e173',
    DengiSrazu = '5ea1b0e26c1f2a001b38c21d',
}

const hasOffersLinks = new Map<GURULEADS_LIST, IPixels>([
    [
        GURULEADS_LIST.WebZaym,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1278&aff_id=2&url_id=2455',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1278&aff_id=2&url_id=2455',
        },
    ],
    [
        GURULEADS_LIST.PayPs,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1278&aff_id=2&url_id=2456',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1278&aff_id=2&url_id=2456',
        },
    ],
    [
        GURULEADS_LIST.VivaDengi,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1278&aff_id=2&url_id=2457',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1278&aff_id=2&url_id=2457',
        },
    ],
    [
        GURULEADS_LIST.OneClickMoney,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1278&aff_id=2&url_id=2458',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1278&aff_id=2&url_id=2458',
        },
    ],
    [
        GURULEADS_LIST.DoZarplaty,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1278&aff_id=2&url_id=2459',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1278&aff_id=2&url_id=2459',
        },
    ],
    [
        GURULEADS_LIST.BystroDengi,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1278&aff_id=2&url_id=2460',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1278&aff_id=2&url_id=2460',
        },
    ],
    [
        GURULEADS_LIST.MigCredit,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1278&aff_id=2&url_id=2461',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1278&aff_id=2&url_id=2461',
        },
    ],
    [
        GURULEADS_LIST.MoneyMan,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1278&aff_id=2&url_id=2462',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1278&aff_id=2&url_id=2462',
        },
    ],
    [
        GURULEADS_LIST.Moneza,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1278&aff_id=2&url_id=2463',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1278&aff_id=2&url_id=2463',
        },
    ],
    [
        GURULEADS_LIST.Zaymer,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1278&aff_id=2&url_id=2464',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1278&aff_id=2&url_id=2464',
        },
    ],
    [
        GURULEADS_LIST.EKapusta,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1235&aff_id=2&url_id=2444',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1235&aff_id=2&url_id=2444',
        },
    ],
    [
        GURULEADS_LIST.Kredito24,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=249&aff_id=2&url_id=2446',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=249&aff_id=2&url_id=2446',
        },
    ],
    [
        GURULEADS_LIST.DengiSrazu,
        {
            click: 'https://sravni.go2cloud.org/aff_c?offer_id=1227&aff_id=2&url_id=2448',
            display: 'https://sravni.go2cloud.org/aff_i?offer_id=1227&aff_id=2&url_id=2448',
        },
    ],
]);

const ids = Object.values(GURULEADS_LIST);
const size = ids.length * 2; // промо формируется по-принципу список продуктов * 2
const order = new Map(ids.map((productId, index) => [productId, index + 1]));

const getProductSortIndex = (microCredit: Microcredits.List.IMicrocredit) =>
    order.get(microCredit._id as GURULEADS_LIST) ?? Number.MAX_SAFE_INTEGER;

const compareProducts = (microCredit1: Microcredits.List.IMicrocredit, microCredit2: Microcredits.List.IMicrocredit) =>
    getProductSortIndex(microCredit1) - getProductSortIndex(microCredit2);

export const loadGuruleadsList = async (params: Microcredits.List.IReq) => {
    const { data } = await client.get<Microcredits.List.IRes>(
        `${getConfig().publicRuntimeConfig.publicUrl}/v1/microCredit/list/`,
        {
            params: {
                ids,
                currency: params.currency,
                limit: params.limit,
                location: params.location,
                skip: params.skip,
            },
            timeout: 1500,
        },
    );

    const updateItems = updateHasOffersLinks(hasOffersLinks);
    // @ts-ignore
    const list = data.items.map(updateItems).sort(compareProducts);
    const promoItems = [...list, ...list];
    const availableProducts = size > promoItems.length ? promoItems.length : size;

    return { ...data, count: availableProducts, total: availableProducts, items: promoItems };
};
