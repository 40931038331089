import { Currency } from '../@types/currency';
import type { List } from '../@types/microcredits';
import type { AddSymbol, GetTemplatePath } from '../@types/utilities';

import { CHANNELS } from './advertising';
import { FILTERS_DICTIONARIES_KEYS } from './dictionaries';

export const ANY_VALUE = 'any';

export enum FILTERS_NAMES {
    LOCATION = 'location',
    AMOUNT = 'amount',
    TERM = 'term',
    TERM_RANGE_FROM = 'termRangeFrom',
    TERM_RANGE_TO = 'termRangeTo',
    RATE = 'rate',
    SECURITY = 'security',
    BENEFITS = 'benefits',
    SOLVENCY_PROOF = 'solvencyProof',
    DOCUMENTS = 'documents',
    EXCLUDED_DOCUMENTS = 'excludedDocuments',
    WITHOUT_DOCUMENTS = 'withoutDocuments',
    ONLY_VITRINS = 'onlyVitrins',
    AS_LANDING = 'asLanding',
    EXCLUDE_ORGANIZATIONS_IDS_FRONT = 'off',
    EXCLUDE_ORGANIZATIONS_IDS_SERVICE = 'excludeOrganizationsIds',
    OFFERS_TYPE = 'offersType',

    AGE = 'age',
    GUARANTEE = 'guarantee',
    OBTAINING_METHOD = 'obtainingMethod',
    REGISTRATION_WAY = 'registrationWay',
    POSTPONEMENT = 'postponement',
    PROLONGATION = 'prolongation',
    BANKS = 'organizations',
    CURRENCY = 'currency',
    SORT = 'sort',
    SEARCH = 'search',

    LIMIT = 'limit',
    LIMIT_MOBILE = 'limitMobile',
    GROUP = 'group',
    ROUTE = 'route',

    // only card block
    INSURANCES = 'insurances',
    COMMISSIONS = 'commission',
    ADV_SUB = 'adv_sub',
    MONETIZATION = 'monetization',
    ADS_ONLY = 'adsOnly',
    METADATA = 'metadata',
    CHANNEL = 'channel',

    // calculator
    PENALTY_AMOUNT = 'penaltyAmount',
    PENALTY_RATE = 'penaltyRate',
    PENALTY_TERM = 'penaltyTerm',

    ONLY_GOOD_OFFERS = 'go', // фильтр для проверяющих
    HAS_OFFERS_DUPLICATE = 'hasOffersDuplicate',
    PHONE_NUMBER = 'phoneNumber',
    INCLUDE_PRODUCT_IDS = 'on', // фильтр для отказной витрины
    CLIENT_ID = 'clientId',
}

export const FILTERS_LABELS: Partial<Record<FILTERS_NAMES, string>> = {
    [FILTERS_NAMES.LOCATION]: 'Город',
    [FILTERS_NAMES.AMOUNT]: 'Сумма займа',
    [FILTERS_NAMES.TERM]: 'На какой срок',
    [FILTERS_NAMES.SECURITY]: 'Залог',
    [FILTERS_NAMES.GUARANTEE]: 'Поручительство',
    [FILTERS_NAMES.OBTAINING_METHOD]: 'Способ получения',
    [FILTERS_NAMES.REGISTRATION_WAY]: 'Оформление',
    [FILTERS_NAMES.SORT]: 'Сортировать',
    [FILTERS_NAMES.POSTPONEMENT]: 'Отсрочка',
    [FILTERS_NAMES.PROLONGATION]: 'Пролонгация',
    [FILTERS_NAMES.AGE]: 'Возраст',
};

export const FILTERS_DICTIONARIES_MAP: Partial<Record<FILTERS_NAMES, string>> = {
    [FILTERS_NAMES.TERM]: FILTERS_DICTIONARIES_KEYS.TERM,
    [FILTERS_NAMES.SECURITY]: FILTERS_DICTIONARIES_KEYS.SECURITY,
    [FILTERS_NAMES.GUARANTEE]: FILTERS_DICTIONARIES_KEYS.GUARANTEE,
    [FILTERS_NAMES.OBTAINING_METHOD]: FILTERS_DICTIONARIES_KEYS.OBTAINING_METHOD,
    [FILTERS_NAMES.REGISTRATION_WAY]: FILTERS_DICTIONARIES_KEYS.REGISTRATION_WAY,
};

const DEFAULT_AMOUNT = undefined;
const DEFAULT_TERM = undefined;
const DEFAULT_AGE = undefined;
const DEFAULT_OBTAINING_METHOD = undefined;
const DEFAULT_REGISTRATION_WAY = undefined;
const DEFAULT_BENEFITS = undefined;
const DEFAULT_SOLVENCY_PROOF = undefined;
const DEFAULT_DOCUMENTS = undefined;
const DEFAULT_EXCLUDED_DOCUMENTS = undefined;
const DEFAULT_WITHOUT_DOCUMENTS = undefined;
export const DEFAULT_LIMIT = 32;
export const DEFAULT_LIMIT_MOBILE = 16;
export const DEFAULT_PRELOADED_LIMIT = 32;
export const DEFAULT_REGION_ROUTE = '6.83.';
const DEFAULT_CURRENCY = Currency.RUB;
const DEFAULT_PROLONGATION = false;
const DEFAULT_POSTPONEMENT = false;
const DEFAULT_GROUP = false;
const DEFAULT_ADS_ONLY = false;
const DEFAULT_METADATA = true;
export const NA_KARTU_PAGE_DEFAULT_AMOUNT = 10000;
export const NA_KARTU_PAGE_DEFAULT_TERM = 10;

const SORT_BY_UPDATED: KeysForBackendSorting = '-advertising.updated';
const SORT_BY_SEA_TRAFFIC: KeysForBackendSorting = 'advertising.positionOnLanding';
const SORT_BY_POSITION_61_DAY_WL_EPC: KeysForBackendSorting = 'advertising.position61DayWLEPC';
const SORT_BY_POSITION_WL_APP_DENIED: KeysForBackendSorting = 'advertising.positionWLAppDenied';
const SORT_BY_POSITION_LOAN_PTS: KeysForBackendSorting = 'advertising.positionLoanPts';
const SORT_BY_POSITION_EPC: KeysForBackendSorting = 'advertising.positionEPC';
const SORT_BY_SEA_TRAFFIC_EPC: KeysForBackendSorting = 'advertising.positionOnLandingEPC';
const SORT_BY_SPEC_OFFER_POSITION_EPC: KeysForBackendSorting = 'advertising.positionOnSpecOffersLandingEPC';
const SORT_BY_POSITION: KeysForBackendSorting = 'advertising.position';

export const MODES = {
    DEFAULT: 'default',
    DEFAULT_AMOUNT: 'default_amount',
    AS_LANDING: 'as_landing',
    AS_UTM: 'as_utm',
    AS_UTM_NA_KARTU: 'as_utm_as_b_test',
    LANDING: 'landing',
    ONLINE: 'online',
    CALCULATOR: 'calculator',
    BESTPRODUCTS: 'best_products',
    NOVINKI: 'novinki',
    WL_PAGE: 'wl_app',
    WL_DENIED_PAGE: 'wl_abandoned_app',
    WL_PAGE_WITH_CHANNEL: 'wl_app_with_channel',
    PARTNER_PAGE: 'partners',
    SIDEBAR_BANNER: 'sidebar_banner',
    SPEC_OFFERS: 'spec_offers',
    KRAKEN: 'special',
    ZAIMY_POD_PTS: 'zaimy-pod-pts',
    KAZAKHSTAN: 'kazakhstan',
    NEW_SPECIAL: 'new_special',
    MFO_PARTNER_2GIS: 'mfo-partner-2gis',
};

const DEFAULT = {
    [FILTERS_NAMES.LOCATION]: DEFAULT_REGION_ROUTE,
    [FILTERS_NAMES.CURRENCY]: DEFAULT_CURRENCY,
    [FILTERS_NAMES.AMOUNT]: DEFAULT_AMOUNT,
    [FILTERS_NAMES.TERM]: DEFAULT_TERM,
    [FILTERS_NAMES.PROLONGATION]: DEFAULT_PROLONGATION,
    [FILTERS_NAMES.POSTPONEMENT]: DEFAULT_POSTPONEMENT,
    [FILTERS_NAMES.AGE]: DEFAULT_AGE,
    [FILTERS_NAMES.OBTAINING_METHOD]: DEFAULT_OBTAINING_METHOD,
    [FILTERS_NAMES.REGISTRATION_WAY]: DEFAULT_REGISTRATION_WAY,
    [FILTERS_NAMES.BENEFITS]: DEFAULT_BENEFITS,
    [FILTERS_NAMES.SOLVENCY_PROOF]: DEFAULT_SOLVENCY_PROOF,
    [FILTERS_NAMES.SECURITY]: DEFAULT_DOCUMENTS,
    [FILTERS_NAMES.DOCUMENTS]: DEFAULT_DOCUMENTS,
    [FILTERS_NAMES.EXCLUDED_DOCUMENTS]: DEFAULT_EXCLUDED_DOCUMENTS,
    [FILTERS_NAMES.WITHOUT_DOCUMENTS]: DEFAULT_WITHOUT_DOCUMENTS,
    [FILTERS_NAMES.LIMIT]: DEFAULT_LIMIT,
    [FILTERS_NAMES.LIMIT_MOBILE]: DEFAULT_LIMIT_MOBILE,
    [FILTERS_NAMES.GROUP]: DEFAULT_GROUP,
    [FILTERS_NAMES.ADS_ONLY]: DEFAULT_ADS_ONLY,
    [FILTERS_NAMES.METADATA]: DEFAULT_METADATA,
    [FILTERS_NAMES.ONLY_VITRINS]: true,
    [FILTERS_NAMES.SORT]: SORT_BY_POSITION_EPC,
};

export const DEFAULT_FILTERS = {
    [MODES.DEFAULT]: DEFAULT,
    [MODES.DEFAULT_AMOUNT]: {
        ...DEFAULT,
        [FILTERS_NAMES.AMOUNT]: NA_KARTU_PAGE_DEFAULT_AMOUNT,
        [FILTERS_NAMES.TERM]: NA_KARTU_PAGE_DEFAULT_TERM,
    },
    [MODES.AS_LANDING]: {
        ...DEFAULT,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.AS_LANDING]: true,
    },
    [MODES.PARTNER_PAGE]: {
        ...DEFAULT,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.PARTNERS,
    },
    [MODES.AS_UTM]: {
        ...DEFAULT,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.SORT]: SORT_BY_SEA_TRAFFIC_EPC,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.SEA_TRAFFIC,
    },
    [MODES.AS_UTM_NA_KARTU]: {
        ...DEFAULT,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.SORT]: SORT_BY_SEA_TRAFFIC_EPC,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.SEA_TRAFFIC,
        [FILTERS_NAMES.AMOUNT]: NA_KARTU_PAGE_DEFAULT_AMOUNT,
        [FILTERS_NAMES.TERM]: NA_KARTU_PAGE_DEFAULT_TERM,
    },
    [MODES.LANDING]: {
        [FILTERS_NAMES.LOCATION]: DEFAULT_REGION_ROUTE,
        [FILTERS_NAMES.CURRENCY]: DEFAULT_CURRENCY,
        [FILTERS_NAMES.LIMIT]: 32,
        [FILTERS_NAMES.LIMIT_MOBILE]: DEFAULT_LIMIT_MOBILE,
        [FILTERS_NAMES.GROUP]: true,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.ONLY_VITRINS]: true,
    },
    [MODES.WL_PAGE]: {
        ...DEFAULT,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.SORT]: SORT_BY_SEA_TRAFFIC,
    },
    [MODES.WL_PAGE_WITH_CHANNEL]: {
        [FILTERS_NAMES.LOCATION]: DEFAULT_REGION_ROUTE,
        [FILTERS_NAMES.CURRENCY]: DEFAULT_CURRENCY,
        [FILTERS_NAMES.LIMIT]: 32,
        [FILTERS_NAMES.LIMIT_MOBILE]: DEFAULT_LIMIT_MOBILE,
        [FILTERS_NAMES.GROUP]: true,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.ONLY_VITRINS]: true,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.WL_ADVERTISING_CHANNEL,
        [FILTERS_NAMES.SORT]: SORT_BY_POSITION_61_DAY_WL_EPC,
    },
    [MODES.WL_DENIED_PAGE]: {
        [FILTERS_NAMES.LOCATION]: DEFAULT_REGION_ROUTE,
        [FILTERS_NAMES.CURRENCY]: DEFAULT_CURRENCY,
        [FILTERS_NAMES.LIMIT]: 32,
        [FILTERS_NAMES.LIMIT_MOBILE]: DEFAULT_LIMIT_MOBILE,
        [FILTERS_NAMES.GROUP]: true,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.ONLY_VITRINS]: true,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.WL_DENIED_PAGE_CHANNEL,
        [FILTERS_NAMES.SORT]: SORT_BY_POSITION_WL_APP_DENIED,
    },
    [MODES.BESTPRODUCTS]: {
        [FILTERS_NAMES.LOCATION]: DEFAULT_REGION_ROUTE,
        [FILTERS_NAMES.CURRENCY]: DEFAULT_CURRENCY,
        [FILTERS_NAMES.AMOUNT]: DEFAULT_AMOUNT,
        [FILTERS_NAMES.TERM]: DEFAULT_TERM,
        [FILTERS_NAMES.PROLONGATION]: DEFAULT_PROLONGATION,
        [FILTERS_NAMES.POSTPONEMENT]: DEFAULT_POSTPONEMENT,
        [FILTERS_NAMES.AGE]: DEFAULT_AGE,
        [FILTERS_NAMES.OBTAINING_METHOD]: DEFAULT_OBTAINING_METHOD,
        [FILTERS_NAMES.BENEFITS]: DEFAULT_BENEFITS,
        [FILTERS_NAMES.SOLVENCY_PROOF]: DEFAULT_SOLVENCY_PROOF,
        [FILTERS_NAMES.DOCUMENTS]: DEFAULT_DOCUMENTS,
        [FILTERS_NAMES.EXCLUDED_DOCUMENTS]: DEFAULT_EXCLUDED_DOCUMENTS,
        [FILTERS_NAMES.WITHOUT_DOCUMENTS]: DEFAULT_WITHOUT_DOCUMENTS,
        [FILTERS_NAMES.LIMIT]: 4,
        [FILTERS_NAMES.GROUP]: true,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.METADATA]: false,
        [FILTERS_NAMES.ONLY_VITRINS]: true,
    },
    [MODES.ONLINE]: {
        [FILTERS_NAMES.LOCATION]: DEFAULT_REGION_ROUTE,
        [FILTERS_NAMES.CURRENCY]: DEFAULT_CURRENCY,
        [FILTERS_NAMES.SEARCH]: '',
        [FILTERS_NAMES.AMOUNT]: DEFAULT_AMOUNT,
        [FILTERS_NAMES.TERM]: DEFAULT_TERM,
        [FILTERS_NAMES.PROLONGATION]: DEFAULT_PROLONGATION,
        [FILTERS_NAMES.POSTPONEMENT]: DEFAULT_POSTPONEMENT,
        [FILTERS_NAMES.AGE]: DEFAULT_AGE,
        [FILTERS_NAMES.OBTAINING_METHOD]: DEFAULT_OBTAINING_METHOD,
        [FILTERS_NAMES.BENEFITS]: DEFAULT_BENEFITS,
        [FILTERS_NAMES.SOLVENCY_PROOF]: DEFAULT_SOLVENCY_PROOF,
        [FILTERS_NAMES.DOCUMENTS]: DEFAULT_DOCUMENTS,
        [FILTERS_NAMES.EXCLUDED_DOCUMENTS]: DEFAULT_EXCLUDED_DOCUMENTS,
        [FILTERS_NAMES.WITHOUT_DOCUMENTS]: DEFAULT_WITHOUT_DOCUMENTS,
        [FILTERS_NAMES.LIMIT]: 64,
        [FILTERS_NAMES.LIMIT_MOBILE]: 32,
        [FILTERS_NAMES.GROUP]: DEFAULT_GROUP,
        [FILTERS_NAMES.ADS_ONLY]: DEFAULT_ADS_ONLY,
        [FILTERS_NAMES.ONLY_VITRINS]: true,
    },
    [MODES.CALCULATOR]: {
        [FILTERS_NAMES.LOCATION]: DEFAULT_REGION_ROUTE,
        [FILTERS_NAMES.CURRENCY]: DEFAULT_CURRENCY,
        [FILTERS_NAMES.AMOUNT]: 10000,
        [FILTERS_NAMES.TERM]: 10,
        [FILTERS_NAMES.RATE]: 0.8,
        [FILTERS_NAMES.LIMIT]: 64,
        [FILTERS_NAMES.LIMIT_MOBILE]: 32,
        [FILTERS_NAMES.ADS_ONLY]: false,
        [FILTERS_NAMES.METADATA]: DEFAULT_METADATA,
        [FILTERS_NAMES.ONLY_VITRINS]: true,
    },
    [MODES.NOVINKI]: {
        [FILTERS_NAMES.LOCATION]: DEFAULT_REGION_ROUTE,
        [FILTERS_NAMES.CURRENCY]: DEFAULT_CURRENCY,
        [FILTERS_NAMES.AMOUNT]: DEFAULT_AMOUNT,
        [FILTERS_NAMES.TERM]: DEFAULT_TERM,
        [FILTERS_NAMES.PROLONGATION]: DEFAULT_PROLONGATION,
        [FILTERS_NAMES.POSTPONEMENT]: DEFAULT_POSTPONEMENT,
        [FILTERS_NAMES.AGE]: DEFAULT_AGE,
        [FILTERS_NAMES.OBTAINING_METHOD]: DEFAULT_OBTAINING_METHOD,
        [FILTERS_NAMES.BENEFITS]: DEFAULT_BENEFITS,
        [FILTERS_NAMES.SOLVENCY_PROOF]: DEFAULT_SOLVENCY_PROOF,
        [FILTERS_NAMES.DOCUMENTS]: DEFAULT_DOCUMENTS,
        [FILTERS_NAMES.EXCLUDED_DOCUMENTS]: DEFAULT_EXCLUDED_DOCUMENTS,
        [FILTERS_NAMES.WITHOUT_DOCUMENTS]: DEFAULT_WITHOUT_DOCUMENTS,
        [FILTERS_NAMES.LIMIT]: DEFAULT_LIMIT,
        [FILTERS_NAMES.LIMIT_MOBILE]: DEFAULT_LIMIT_MOBILE,
        [FILTERS_NAMES.GROUP]: true,
        [FILTERS_NAMES.ADS_ONLY]: DEFAULT_ADS_ONLY,
        [FILTERS_NAMES.METADATA]: DEFAULT_METADATA,
        [FILTERS_NAMES.SORT]: SORT_BY_UPDATED,
        [FILTERS_NAMES.ONLY_VITRINS]: true,
    },
    [MODES.SIDEBAR_BANNER]: {
        ...DEFAULT,
        [FILTERS_NAMES.LIMIT]: 1,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.SIDEBAR_BANNER,
    },
    [MODES.SPEC_OFFERS]: {
        ...DEFAULT,
        [FILTERS_NAMES.LIMIT_MOBILE]: 26,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.SPEC_OFFERS,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.SORT]: SORT_BY_SPEC_OFFER_POSITION_EPC,
    },
    [MODES.ZAIMY_POD_PTS]: {
        ...DEFAULT,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.ZAIMY_POD_PTS,
        [FILTERS_NAMES.SORT]: SORT_BY_POSITION_LOAN_PTS,
    },
    [MODES.KAZAKHSTAN]: {
        ...DEFAULT,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.KAZAKHSTAN,
        [FILTERS_NAMES.ADS_ONLY]: true,
    },
    [MODES.NEW_SPECIAL]: {
        ...DEFAULT,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.NEW_SPECIAL,
        [FILTERS_NAMES.ADS_ONLY]: true,
    },
    [MODES.MFO_PARTNER_2GIS]: {
        ...DEFAULT,
        [FILTERS_NAMES.CHANNEL]: CHANNELS.MFO_PARTNER_2GIS,
        [FILTERS_NAMES.ADS_ONLY]: true,
        [FILTERS_NAMES.SORT]: SORT_BY_POSITION,
    },
};

export const FILTERS_SCHEME = {
    [FILTERS_NAMES.CURRENCY]: String,
    [FILTERS_NAMES.LOCATION]: String,
    [FILTERS_NAMES.AMOUNT]: Number,
    [FILTERS_NAMES.TERM]: Number,
    [FILTERS_NAMES.TERM_RANGE_FROM]: Number,
    [FILTERS_NAMES.TERM_RANGE_TO]: Number,
    [FILTERS_NAMES.RATE]: Number,
    [FILTERS_NAMES.SEARCH]: String,
    [FILTERS_NAMES.PENALTY_AMOUNT]: Number,
    [FILTERS_NAMES.PENALTY_TERM]: Number,
    [FILTERS_NAMES.PENALTY_RATE]: Number,
    [FILTERS_NAMES.OBTAINING_METHOD]: String,
    [FILTERS_NAMES.REGISTRATION_WAY]: String,
    [FILTERS_NAMES.SORT]: String,
    [FILTERS_NAMES.PROLONGATION]: Boolean,
    [FILTERS_NAMES.POSTPONEMENT]: Boolean,
    [FILTERS_NAMES.BANKS]: [String],
    [FILTERS_NAMES.SECURITY]: [String],
    [FILTERS_NAMES.AGE]: Number,
    [FILTERS_NAMES.BENEFITS]: [String],
    [FILTERS_NAMES.SOLVENCY_PROOF]: String,
    [FILTERS_NAMES.DOCUMENTS]: [String],
    [FILTERS_NAMES.EXCLUDED_DOCUMENTS]: [String],
    [FILTERS_NAMES.ONLY_VITRINS]: Boolean,
    [FILTERS_NAMES.AS_LANDING]: Boolean,
    [FILTERS_NAMES.EXCLUDE_ORGANIZATIONS_IDS_FRONT]: String,
    [FILTERS_NAMES.INCLUDE_PRODUCT_IDS]: String,

    [FILTERS_NAMES.OFFERS_TYPE]: String,

    [FILTERS_NAMES.GROUP]: Boolean,

    [FILTERS_NAMES.LIMIT]: Number,

    [FILTERS_NAMES.METADATA]: Boolean,
    [FILTERS_NAMES.ADS_ONLY]: Boolean,
    [FILTERS_NAMES.CHANNEL]: String,
    [FILTERS_NAMES.CLIENT_ID]: String,
};

export const TERM_SETTINGS = {
    min: 0,
    max: 365,
    maxLength: 3,
    steps: [1, 10, 50],
};

export const RATE_SETTINGS = {
    min: 0,
    max: 1,
};

type KeysForBackendSorting =
    | AddSymbol<GetTemplatePath<Required<List.IMicrocredit>>, '-' | ''>
    | 'popular'
    | '-approvalProbability.exact';

type KeysForSortValues =
    | 'RATE_MORE'
    | 'AMOUNT_LESS'
    | 'ADVERTISING'
    | 'TERM_ASC'
    | 'CONSIDERATION_MORE'
    | 'RANK'
    | 'AMOUNT_MORE'
    | 'RATE_LESS'
    | 'TERM_DESC'
    | 'CONSIDERATION_LESS'
    | 'MORE_ISSUED'
    | 'POPULAR'
    | 'APPROVAL_PROBABILITY';

export const SORT_VALUES: Record<KeysForSortValues, KeysForBackendSorting> = {
    RATE_MORE: 'details.rate.from',
    RATE_LESS: '-details.rate.from',
    POPULAR: 'popular',
    AMOUNT_MORE: 'amountRange.from',
    AMOUNT_LESS: '-amountRange.from',
    ADVERTISING: 'advertising.position',
    TERM_ASC: 'details.term.fromValue',
    TERM_DESC: '-details.term.toValue',
    CONSIDERATION_MORE: 'considerationTime.fromValue',
    CONSIDERATION_LESS: '-considerationTime.fromValue',
    RANK: 'rank',
    MORE_ISSUED: '-aprLeads',
    APPROVAL_PROBABILITY: '-approvalProbability.exact',
};

export const SORT_LABELS = {
    [SORT_VALUES.RATE_MORE]: 'По ставке больше',
    [SORT_VALUES.RATE_LESS]: 'По ставке меньше',
    [SORT_VALUES.AMOUNT_MORE]: 'По сумме больше',
    [SORT_VALUES.AMOUNT_LESS]: 'По сумме меньше',
    [SORT_VALUES.CONSIDERATION_MORE]: 'По времени выдачи дольше',
    [SORT_VALUES.CONSIDERATION_LESS]: 'По времени выдачи быстрее',
    [SORT_VALUES.TERM_DESC]: 'По сроку больше',
    [SORT_VALUES.TERM_ASC]: 'По сроку меньше',
    [SORT_VALUES.ADVERTISING]: 'По популярности',
    [SORT_VALUES.POPULAR]: 'По популярности',
    [SORT_VALUES.APPROVAL_PROBABILITY]: 'По вероятности одобрения',
};
const SORT_LABELS_AB = {
    [SORT_VALUES.ADVERTISING]: 'Популярные',
    [SORT_VALUES.RATE_LESS]: 'По ставке',
    [SORT_VALUES.APPROVAL_PROBABILITY]: 'По вероятности одобрения',
};

const COMMON_SOURCE = [
    {
        label: SORT_LABELS_AB[SORT_VALUES.RATE_LESS],
        value: SORT_VALUES.RATE_MORE,
    },
];

export const SORT_SOURCE_AB = [
    {
        label: SORT_LABELS_AB[SORT_VALUES.ADVERTISING],
        value: SORT_VALUES.POPULAR,
    },
    ...COMMON_SOURCE,
];

export const SORT_SOURCE_UTM = [
    {
        label: SORT_LABELS_AB[SORT_VALUES.ADVERTISING],
        value: 'advertising.positionOnLanding',
    },

    ...COMMON_SOURCE,
];

export const SORT_SOURCE_NA_KARTU = [
    {
        label: SORT_LABELS_AB[SORT_VALUES.ADVERTISING],
        value: SORT_VALUES.POPULAR,
    },
    {
        label: SORT_LABELS_AB[SORT_VALUES.APPROVAL_PROBABILITY],
        value: SORT_VALUES.APPROVAL_PROBABILITY,
    },
];
