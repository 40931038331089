export const MONETIZATION_TYPES = {
    REFERRAL: 'referral',
    REQUEST_SHORT: 'request-short',
    REQUEST_LONG: 'request-long',
    COMMISSION: 'commission',
    PROMO_CODE: 'promo-code',
    APPROVAL: 'approval',
};

export const CHANNELS = {
    WL_ADVERTISING_CHANNEL: 'mfo-selection-app-61-day-wl',
    WL_DENIED_PAGE_CHANNEL: 'mfo-wl-app-denied',
    SEA_TRAFFIC: 'sea-traffic',
    PARTNERS: 'partners',
    SIDEBAR_BANNER: 'sidebar-mfo-web',
    SPEC_OFFERS: 'kraken',
    ZAIMY_POD_PTS: 'zaimy-pod-pts',
    KAZAKHSTAN: 'kazakhstan',
    NEW_SPECIAL: 'new_special',
    MFO_PARTNER_2GIS: 'mfo-partner-2gis',
};
