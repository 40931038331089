import type { NextWebVitalsMetric } from 'next/app';

import logger from '@sravni/server-utils/lib/logger';
import request from '@sravni/server-utils/lib/request';

import { API_PATH } from '../config/routes';

export const sendWebVitals = (metrics: NextWebVitalsMetric): void => {
    try {
        request.post(`${API_PATH}/web/vitals`, {
            metrics,
            // @ts-ignore
            product: window.__NEXT_REDUX_STORE__?.getState()?.config?.name,
        });
    } catch (error) {
        logger.error({ message: 'Не удалось отправить данные о производительности' });
    }
};
