import type { FC } from 'react';
import React from 'react';
import { Provider } from 'react-redux';

import type { IGlobalState } from '@src/reducers';
import { getOrCreateStore } from '@src/store/getOrCreateStore';

interface Props {
    initialReduxState: IGlobalState;
}

export const StoreProvider: FC<Props> = ({ initialReduxState, children }) => {
    const reduxStore = getOrCreateStore(initialReduxState);

    return <Provider store={reduxStore}>{children}</Provider>;
};
