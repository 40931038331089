import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type StatusData from '@src/@types/StatusData';

import type { DictionaryAlias, DictionaryValue, IDictionary } from '../@types/dictionary';
import DataStatus from '../enums/DataStatus';
import { getDictionariesFieldsMap } from '../helpers/dictionaries';

import type { IGlobalState } from '.';

type IState = StatusData<Record<string, IDictionary>>;

const initialState: IState = {
    status: DataStatus.Default,
    data: {},
};

export const setupDictionaries = createAction<StatusData<IDictionary[]>>('dictionaries/setup');

export const dictionariesReducer = createReducer<IState>({}, initialState).on(
    setupDictionaries,
    (_state, payload: StatusData<IDictionary[]>): IState => ({
        ...payload,
        data: payload.data?.reduce<Record<string, IDictionary>>((acc, item) => {
            acc[item.alias] = item;

            return acc;
        }, {}),
    }),
);

const selectDictionaries = (state: IGlobalState) => state.dictionaries.data;

export function useDictionaries() {
    return useSelector(selectDictionaries);
}

export function useDictionariesFieldsMap(): {
    [Key in DictionaryAlias]: Record<DictionaryValue<Key>, string>;
} {
    const dictionaries = useDictionaries();
    return useMemo(() => getDictionariesFieldsMap(dictionaries), [dictionaries]);
}

export const getDictionaryFieldsByAlias = (dictionaries: IGlobalState['dictionaries'], alias: DictionaryAlias) => {
    const dictionariesItems = dictionaries.data;

    return dictionariesItems[alias]?.fields || [];
};
