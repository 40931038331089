import type * as Microcredits from '@src/@types/microcredits';

export interface IPixels {
    click: string;
    display: string;
}

export const updateHasOffersLinks =
    <T extends string>(hasOffersLinks: Map<T, IPixels>) =>
    (microCredit: Microcredits.List.IMicrocredit) => ({
        ...microCredit,
        advertising: {
            ...microCredit.advertising,
            isPartner: false,
            monetization: {
                ...microCredit.advertising?.monetization,
                pixels: {
                    ...microCredit.advertising?.monetization?.pixels,
                    ...hasOffersLinks.get(microCredit._id as T),
                },
            },
        },
    });
