import path from 'path';

import { Application } from '@src/@types/app';

export const checkTrailingSlash = (ctx: Application.ReduxNextPageContext) => {
    if (!ctx.req?.url) {
        return;
    }

    const { pathname } = new URL(ctx.req.url, `http://${ctx.req.headers.host}`);
    const isFile = Boolean(path.extname(pathname));

    if (isFile) {
        return;
    }

    const hasTrailingSlash = pathname.endsWith('/');

    if (!hasTrailingSlash) {
        ctx.res?.writeHead(301, {
            Location: `${pathname}/`,
        });
        ctx.res?.end();
    }
};
