import type * as Dictionaries from '@src/@types/dictionary';

export const getDictionariesFieldsMap = (dictionaries: Dictionaries.IList) =>
    Object.keys(dictionaries).reduce((acc, alias) => {
        acc[alias] = dictionaries[alias].fields.reduce((map, field) => {
            map[field.value] = field.name;

            return map;
        }, Object.create(null));

        return acc;
    }, Object.create(null));

export const printDictionaryFieldsMap = <T extends Dictionaries.DictionaryAlias>(
    fields: Record<Dictionaries.DictionaryValue<T>, string>,
    values: string[],
    delimiter = ', ',
) =>
    values
        .map((value) => fields[value as Dictionaries.DictionaryValue<T>] || false)
        .filter(Boolean)
        .join(delimiter);

const getDictionaryFields = (dictionaries: Dictionaries.IList, key: Dictionaries.DictionaryAlias) =>
    dictionaries?.[key]?.fields || [];

export const getDictionariesFieldsForSelect = (dictionaries: Dictionaries.IList, key: Dictionaries.DictionaryAlias) => {
    const fields = getDictionaryFields(dictionaries, key);

    return fields.map((item) => ({
        label: item.name,
        value: item.value,
    }));
};

export const getBenefitsNamesByValues = (
    dictionariesFields: Dictionaries.DictionaryFields,
    dictionariesValues: string[],
): string[] =>
    dictionariesValues
        .map((value) => dictionariesFields.find((field) => field.value === value)?.name)
        .filter((item): item is string => Boolean(item));
