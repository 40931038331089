import * as qs from 'querystring';

import castArray from 'lodash/castArray';

// @ts-ignore
function getValue(value, Type) {
    if (!Type || value === undefined) {
        return value;
    }

    if (Array.isArray(Type)) {
        const [InnerType] = Type;

        return castArray(value).map((innerValue) => getValue(innerValue, InnerType));
    }

    if (Type === Boolean) {
        if (typeof value === 'string') {
            return value === 'true';
        }
    }

    try {
        return Type(value);
    } catch (e) {
        console.log('Query parser: wrong value with type', Type, value);
    }
}

/**
 * Decode query
 * @param props
 */

interface IParseProps<T> {
    scheme: T;
    defaultValues?: {
        [key in keyof T]?: any;
    };
    query:
        | string
        | {
              [key in keyof T]?: any;
          };
}

export function parse<T extends IParseProps<T['scheme']>>(props: T) {
    const { scheme, defaultValues = {}, query } = props;

    const queryObject = typeof query === 'string' ? qs.parse(query) : query;

    const output = {};

    Object.keys(scheme).forEach((key) => {
        // @ts-ignore
        const value = getValue(queryObject[key], scheme[key]);

        // @ts-ignore
        output[key] = value === undefined ? defaultValues[key] : value;
    });

    return output as Record<keyof T['scheme'], any>;
}

export const getRouteParams = (query: Record<string, any>) =>
    Object.keys(query).reduce((output: Record<string, any>, key: string) => {
        if (key.startsWith('param')) {
            output[key] = query[key];
        }

        return output;
    }, {});

export const removeRouteParams = (query: Record<string, any>) =>
    Object.keys(query).reduce((output: Record<string, any>, key: string) => {
        if (!key.startsWith('param')) {
            output[key] = query[key];
        }

        return output;
    }, {});

export const stringifyPath = (url: string, query: Record<string, any>) => {
    if (Object.keys(query).length) {
        return `${url}?${qs.stringify(query)}`;
    }

    return url;
};
