export const RUSSIA_AMOUNT_FIELD_SETTINGS = {
    min: 0,
    max: 100_000,
    maxLength: 7,
    steps: [1000, 10_000, 50_000],
};

export const KAZAKHSTAN_AMOUNT_FIELD_SETTINGS = {
    min: 10_000,
    max: 300_000,
    maxLength: 7,
    steps: [10_000, 40_000, 150_000],
};

export const GLOBAL_AMOUNT_SETTINGS = {
    min: 0,
    max: Math.max(RUSSIA_AMOUNT_FIELD_SETTINGS.max, KAZAKHSTAN_AMOUNT_FIELD_SETTINGS.max),
    maxLength: Math.max(RUSSIA_AMOUNT_FIELD_SETTINGS.maxLength, KAZAKHSTAN_AMOUNT_FIELD_SETTINGS.maxLength),
};
