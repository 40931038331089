import type { Store } from 'redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { isProduction } from '@src/utils';

import type { IGlobalState } from '../reducers';
import reducers from '../reducers';

const composeEnhancers =
    (typeof window !== 'undefined' && !isProduction && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const initializeStore = (initialState?: IGlobalState): Store<IGlobalState> =>
    // TODO fix types
    // @ts-ignore
    createStore(reducers, initialState, composeEnhancers(applyMiddleware(thunkMiddleware)));
