import type { Reducer } from 'redux';
import { combineReducers } from 'redux';

import { headerReducer as header } from '@src/reducers/header';

import { bannersReducer as banners } from './banners';
import { brandingReducer as branding } from './branding';
import { dictionariesReducer as dictionaries } from './dictionaries';
import { historyReducer as history } from './history';
import { locationsReducer as locations } from './locations';
import { metadataReducer as metadata } from './metadata';
import { microcreditsReducer as credits } from './microcredits';
import { microcreditUserDealsReducer as microcreditUserDeals } from './microcreditUserDeals';
import { organizationsReducer as organizations } from './organizations';
import { productReducer as product } from './product';
import { reviewsReducer as reviews } from './reviews';
import { routeReducer as route } from './route';
import { seoReducer as seo } from './seo';
import { siteSettingsReducer as siteSettings } from './siteSettings';
import { userReducer as user } from './user';

export interface IGlobalState {
    history: ReturnType<typeof history>;
    siteSettings: ReturnType<typeof siteSettings>;
    seo: ReturnType<typeof seo>;
    user: ReturnType<typeof user>;
    locations: ReturnType<typeof locations>;
    credits: ReturnType<typeof credits>;
    product: ReturnType<typeof product>;
    dictionaries: ReturnType<typeof dictionaries>;
    banners: ReturnType<typeof banners>;
    branding: ReturnType<typeof branding>;
    reviews: ReturnType<typeof reviews>;
    route: ReturnType<typeof route>;
    header: ReturnType<typeof header>;
    organizations: ReturnType<typeof organizations>;
    metadata: ReturnType<typeof metadata>;
    microcreditUserDeals: ReturnType<typeof microcreditUserDeals>;
}
const reducer: Reducer<IGlobalState> = combineReducers<IGlobalState>({
    history,
    siteSettings,
    seo,
    user,
    dictionaries,
    locations,
    credits,
    product,
    banners,
    branding,
    route,
    reviews,
    header,
    organizations,
    metadata,
    microcreditUserDeals,
});

export default reducer;
