import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type { MicrocreditsDto } from '@src/@types/microcreditsDto';
import type { IGlobalState } from '@src/reducers/index';

import DataStatus from '../enums/DataStatus';

type IState = {
    data: MicrocreditsDto;
    status: DataStatus;
};

const initialState: IState = {
    data: {
        deals: [],
    },
    status: DataStatus.Default,
};

export const setMicrocreditUserDeals = createAction<IState>('deals/set');

export const microcreditUserDealsReducer = createReducer<IState>({}, initialState).on(
    setMicrocreditUserDeals,
    (_state, payload) => payload,
);

const selectMicrocreditUserDealsState = (state: IGlobalState): MicrocreditsDto => state.microcreditUserDeals.data;

export const useMicrocreditUserDealsState = () => useSelector(selectMicrocreditUserDealsState);
