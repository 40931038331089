import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';

import type { Seo } from '@sravni/types/lib/seo';

import type { IFilters } from '@src/@types/microcredits';
import type StatusData from '@src/@types/StatusData';
import { preparePath } from '@src/utils/routing';

import DataStatus from '../enums/DataStatus';

import type { IGlobalState } from './index';

type IState = {
    filters: StatusData<IFilters | null>;
    seo: StatusData<Seo | null>;
};

export const setSeoData = createAction('seo/data/set', (seo: StatusData<Seo | null>) => ({
    seo,
}));

export const setSeoFilters = createAction('seo/filters/set', (filters: StatusData<IFilters | null>) => ({
    filters,
}));

const initialState: IState = {
    seo: {
        data: null,
        status: DataStatus.Default,
    },
    filters: {
        data: null,
        status: DataStatus.Default,
    },
};

export const seoReducer = createReducer<IState>({}, initialState)
    .on(setSeoData, (state, { seo }) => ({
        ...state,
        seo,
    }))
    .on(setSeoFilters, (state, { filters }) => ({
        ...state,
        filters,
    }));

interface ISeoStateHookResult {
    seo: Seo | null;
    filters: IFilters | null;
    pathname?: string;
}

const seoSelector = (state: IGlobalState): Seo | null => state.seo.seo.data;
const seoFiltersSelector = (state: IGlobalState): IFilters | null => state.seo.filters.data;

export function useSeoState(): ISeoStateHookResult {
    const { asPath } = useRouter();
    const pathname = preparePath(asPath);

    const seo = useSelector(seoSelector);
    const filters = useSelector(seoFiltersSelector);

    return {
        pathname,
        seo,
        filters,
    };
}
