import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';
import { v4 as uuid } from 'uuid';

import type { IAdvertising } from '@src/@types/advertising';
import type * as ClientTypes from '@src/@types/clienttypes';
import type * as Microcredits from '@src/@types/microcredits';
import { fetchProduct, fetchProductAdvertising } from '@src/services/product';
import type { ReduxAction } from '@src/store/types';

import type { IGlobalState } from '.';

interface ITermOption {
    value: string;
    label: string;
    minutes: number;
}

interface IState {
    termOptions: ITermOption[];
    key: string | null;
    query?: Microcredits.ICardQuery;
    defaultQuery?: Partial<Microcredits.ICardQuery>;
    source?: Microcredits.Card.IRes;
    advertising: {
        product?: string;
        location?: string;
        body?: IAdvertising;
    };
    error: any;
    loading: boolean;
}

const initialState: IState = {
    termOptions: [],
    key: null,
    loading: false,
    error: null,
    advertising: {},
};

interface IAdvertisingSetProps {
    body?: IAdvertising;
    product?: string;
    location?: string;
}

const setState = createAction<Partial<IState>>('microCredit/item/set/state');
const setAdvertising = createAction<IAdvertisingSetProps>('microCredit/item/set/advertising');

export const productReducer = createReducer<IState>({}, initialState)
    .on(setState, (state, payload) => ({ ...state, ...payload }))
    .on(setAdvertising, (state, payload) => ({ ...state, advertising: { ...state.advertising, ...payload } }));

export const selectCardState = (state: IGlobalState): IState => state.product;
export const selectItemSource = (state: IGlobalState) => state.product.source;
const selectCardAdvertising = (state: IGlobalState) => state.product.advertising?.body;
const selectCardRates = (state: IGlobalState) => state.product.source?.rates;

const makeCardKey = (organizationAlias: string, alias: string) => `${organizationAlias}-${alias}`;

interface IFetchCardParams {
    organizationAlias: string;
    alias: string;
}

function fetchCard(params: IFetchCardParams): ReduxAction {
    return async (dispatch) => {
        const { organizationAlias, alias } = params;

        // clear state and set loading true
        dispatch(
            setState({
                key: makeCardKey(organizationAlias, alias),
                advertising: {},
                source: undefined,
                query: undefined,
                defaultQuery: undefined,
                loading: true,
                error: undefined,
            }),
        );

        // eslint-disable-next-line @typescript-eslint/init-declarations
        let source;
        // eslint-disable-next-line @typescript-eslint/init-declarations
        let error;

        try {
            source = await fetchProduct(organizationAlias, alias);
        } catch (e) {
            error = e;
        }

        dispatch(
            setState({
                source,
                error,
                loading: false,
            }),
        );
    };
}

export function fetchCardIfNeeded(params: IFetchCardParams): ReduxAction {
    /**
     * todo: Reset card query when we left this page and go to the list
     * fill some filters and go to this credit again
     */
    return (dispatch, getState) => {
        const nextKey = makeCardKey(params.organizationAlias, params.alias);
        const { key } = selectCardState(getState());

        if (key !== nextKey) {
            return dispatch(fetchCard(params));
        }
    };
}

interface IFetchAdvertisingParams {
    id: string;
    location: string;
}

const fetchCardAdvertising = (params: IFetchAdvertisingParams): ReduxAction => async (dispatch) => {
    const { id, location } = params;

    try {
        const { advertising = {} } = await fetchProductAdvertising(id, { location });
        const body = { ...advertising, advSub: uuid() };

        dispatch(setAdvertising({ body, product: id, location }));
    } catch (_) {
        // ignore error
    }
};

export const fetchCardAdvertisingIfNeeded = (params: IFetchAdvertisingParams): ReduxAction => (dispatch, getState) => {
    const { id, location } = params;
    const { advertising } = selectCardState(getState());

    if (advertising.body === undefined || advertising.product !== id || advertising.location !== location) {
        return dispatch(fetchCardAdvertising(params));
    }
};

export const useCard = (): ClientTypes.IClientMicrocredit => {
    const cardState = useSelector(selectCardState);

    return useMemo(() => {
        const { source, advertising } = cardState;

        return {
            advertising: advertising.body,
            ...source,
        };
    }, [cardState]);
};
export const useCardAdvertising = () => useSelector(selectCardAdvertising);

export const useCardRates = () => useSelector(selectCardRates)?.RUB ?? [];
